import { animatedCollapse, animatedExpand } from '@/helpers/animated';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.v4.faq');
  if (!node) { return; }

  $('article.v4.faq .b-faq-v4').on('click', ({ currentTarget }) => {
    const node = currentTarget.querySelector('.body');

    if (currentTarget.classList.contains('is-expanded')) {
      animatedCollapse(node);
      currentTarget.classList.remove('is-expanded');
    } else {
      animatedExpand(node);
      currentTarget.classList.add('is-expanded');
    }
  });
});
