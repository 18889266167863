import WorldMap from '@/views/world_map';
import trackVk from '@/helpers/track_vk';

pageLoad('storages_index', () => {
  if (!document.querySelector('.storages-v1-index')) { return; }

  const worldMapNode = document.querySelector('.b-world-map');
  if (worldMapNode) {
    new WorldMap(worldMapNode);
    return;
  }

  $('a.read-more').on('click', ({ currentTarget }) => {
    $(currentTarget).addClass('active');
    $('.additional-seo .spoiler').addClass('active');
  });

  const $node = $('#storages_app');
  renderStoragesApp($node[0], {
    mapType: $node.data('map_type'),
    storages: $node.data('storages'),
    mapDataNode: $node[0],
    presetFilterOptions: $node.data('preset_filter_options'),
    isDistricts: $node.data('is_districts'),
    allBoxSizes: $node.data('all_box_sizes'),
    leadProps: $node.data('lead-props')
  });
  trackVk('view_storages');
});

export async function renderStoragesApp(node, props) {
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: App } = await import('@/vue/storages_map-v1/index');

  return createApp(App, props).mount(node);
}
