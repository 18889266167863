import { I18n } from 'i18n-js';
import translations from '@/i18n/translations.json';

const i18n = new I18n(translations);

const pluralize = (_i18n, count) => {
  if (count === 0) {
    return 'zero';
  }

  const mod10 = count % 10;
  const mod100 = count % 100;
  let key;

  const one = mod10 === 1 && mod100 !== 11;
  const few = [2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100);
  const many =
    mod10 === 0 ||
    [5, 6, 7, 8, 9].includes(mod10) ||
    [11, 12, 13, 14].includes(mod100);

  if (one) {
    key = 'one';
  } else if (few) {
    key = 'few';
  } else if (many) {
    key = 'many';
  } else {
    // key = 'other';
    console.error(`unreacheble "other" pluralization for ${count} count`);
    key = 'many';
  }

  return [key];
};
i18n.pluralization.register('ru', pluralize);
i18n.pluralization.register('et', pluralize);

export const t = i18n.t.bind(i18n);
export const prepared = key =>
  (key2, options) => (
    t(`${key}.${key2}`, options)
  )
;
export default i18n;
