import SweetScroll from '@/helpers/sweet_scroll';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.v4.reviews .load-more');
  if (!node) { return; }

  $(node).on('click', () => {
    const scrollY = window.scrollY;
    $('article.v4.reviews').addClass('is-loaded_more');
    requestAnimationFrame(async () => {
      window.scrollTo(0, scrollY);
      (await SweetScroll.asyncInstance()).toElement(
        document.querySelector('article.v4.reviews .reviews .column:nth-child(2)'),
        { duration: 750, offset: -100 }
      );
    });
  });
});
