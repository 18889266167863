// look for original source code in shikimori
import delay from 'delay';

export default function showModal({
  $modal,
  $trigger,
  $outerNode,
  show,
  hide,
  onlyShow,
  isHiddenCheck,
  isIgnored = false,
  clickEventName = 'click'
}) {
  // console.log('showModal', {
  //   $modal,
  //   $trigger,
  //   $outerNode,
  //   show,
  //   hide,
  //   onlyShow,
  //   isIgnored,
  //   isHiddenCheck,
  //   clickEventName
  // });
  let ignoreNextEvent = false;

  function checkHidden() {
    return isHiddenCheck ?
      isHiddenCheck() :
      $modal.css('display') === 'none';
  }

  function toggleModal({ type }) {
    if (type !== 'focus' && isIgnored && isIgnored()) { return; }
    const eventName = checkHidden() || type === 'focus' ?
      'modal:show' :
      'modal:hide';

    if (eventName === 'modal:hide' && onlyShow) { return; }
    if (ignoreNextEvent) { return; }

    $modal.trigger(eventName);

    // to prevent immediately click after focus
    ignoreNextEvent = true;
    delay().then(() => ignoreNextEvent = false);
  }

  function tryCloseModal(e) {
    // console.log('tryCloseModal', e);
    const $target = $(e.target);
    const isInside = e.target === ($outerNode || $modal)[0] ||
      $target.closest($outerNode || $modal).length;

    if (isInside || !$target.parents('html').length) { return; }

    $modal.trigger('modal:hide');
  }

  function closeModalOnEsc({ keyCode }) {
    if (keyCode !== 27) {
      return;
    }

    $modal.trigger('modal:hide');
  }

  function unbindHandlers() {
    // console.log('unbindHandlers', clickEventName);
    $(document.body).off(clickEventName, tryCloseModal);
    $(document.body).off('focus', '*', tryCloseModal);
    $(document.body).off('keydown', closeModalOnEsc);
  }

  if ($trigger?.constructor === String) {
    $(document).on(clickEventName, $trigger, toggleModal);
  } else {
    // console.log(`$trigger?.on("${clickEventName}", toggleModal)`);
    $trigger?.on(clickEventName, toggleModal);
  }

  $modal
    .on('modal:show', e => {
      // console.log('modal:show', e);
      e.stopPropagation();
      if (!checkHidden()) { return; }

      if (show) {
        show();
      } else {
        $modal.show();
      }

      delay().then(() => {
        // console.log('bindHandlers', clickEventName);
        $(document.body).on(clickEventName, tryCloseModal);
        $(document.body).on('focus', '*', tryCloseModal);
        $(document.body).on('keydown', closeModalOnEsc);

        $(document).one('turbolinks:before-cache', () => {
          if (!checkHidden()) {
            $modal.trigger('modal:hide');
          }
          unbindHandlers();
        });
      });
    })
    .on('modal:hide', e => {
      // console.log('modal:hide', e);
      // stopImmediatePropagation prevents modal:hide handler in makeModal
      e.stopPropagation();
      if (checkHidden()) { return; }

      if (hide) {
        hide();
      } else {
        $modal.hide();
      }

      unbindHandlers();

      if ($trigger?.is(':focus')) {
        $trigger.blur();
      }
    });

  return {
    $modal,
    $trigger,
    $outerNode,
    show() {
      $modal.trigger('modal:show');
      return this;
    },
    hide() {
      if (checkHidden()) { return; }

      $modal.trigger('modal:hide');
      return this;
    },
    destroy: unbindHandlers
  };
}
