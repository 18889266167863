export default class View {
  constructor(node, ...args) {
    this.$node = $(node);
    [this.node] = this.$node;

    if (this.initialize && node !== undefined) {
      this.initialize(...args);
    }
  }

  on(...args) {
    return this.$node.on(...args);
  }

  off(...args) {
    return this.$node.off(...args);
  }

  trigger(...args) {
    this.$node.trigger(...args);
  }

  $(selector) {
    return $(selector, this.$node);
  }

  html(html) {
    this.$node.html(html);
  }
}
