import makeVueModal from '@/views/modal/make_vue_modal';

let contactsModal = null;

pageLoad('pages_root', async () => {
  if (!document.body.className.includes('v4')) { return; }

  $('article.slide_6 button.lead').one('click', async ({ currentTarget }) => {
    const { default: vueComponent } = await import('@/vue/modals/contacts');
    contactsModal ||= makeVueModal({
      trigger: currentTarget,
      vue: { Component: vueComponent }
    });
    contactsModal.show();
  });
});

$(document).on('turbolinks:before-cache', () => {
  if (contactsModal) {
    contactsModal.destroy();
    contactsModal = null;
  }
});
