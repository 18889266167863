import SweetScroll from '@/helpers/sweet_scroll';
import { toLandingLeadForm } from '@/articles/landing_lead_form';

import { buildSingleStorageMap } from '../storages-v1/show';

const IMAGE_ACTIVE = '/assets/static/b-map/marker-regular.svg';

let photosSwiper = null;

pageUnload('boxes_show', () => {
  if (photosSwiper) {
    photosSwiper.destroy();
    photosSwiper = null;
  }
});

pageLoad('boxes_show', async () => {
  if (!document.querySelector('.boxes-v1-show')) { return; }
  const $photos = $('.photos-slider .photo');

  if ($photos.length) {
    const [{ default: Swiper }, { Navigation }] = await Promise.all([
      import('swiper'),
      import('swiper/modules')
    ]);

    $photos.magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      }
    });

    if ($photos.length > 1) {
      photosSwiper = new Swiper('.b-photos', {
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 0,
        wrapperClass: 'photos-slider',
        slideClass: 'photo',
        modules: [Navigation],
        navigation: {
          nextEl: '.photos-slider-next',
          prevEl: '.photos-slider-prev'
        },
        loop: true
      });
    }
  }

  $('article.questions .learn-more').on('click', toLandingLeadForm);
  $('.blue-block a.lead').on('click', toLandingLeadForm);

  $('.short-links a.delivery').on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.delivery'), { duration: 750 }
    );
  });

  $('.short-links a.how_to_pay').on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.slide_9'), { duration: 750 }
    );
  });

  $('.short-links a.cannot_be_stored').on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.cannot_be_stored'), { duration: 750 }
    );
  });

  $('.short-links a.path_to_storage').on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.slide_12'), { duration: 750 }
    );
  });

  buildSingleStorageMap();
});
