import {
  disablePageScroll,
  enablePageScroll,
  addScrollableSelector,
  removeScrollableSelector
} from 'scroll-lock';

import showModal from '@/utils/show_modal';
import { isMobile } from '@/helpers/mobile_detect';

const MOBILE_HANDLE_SWIPE_THRESHOLD = 40;

export default function({
  trigger,
  cssClass,
  isSlideModal = false,
  isCloseOnSubmit = false,
  isModalCloseHandler = true,
  isDestroyOnHide = false,
  vue: {
    Component,
    props = null,
    eventHandlers = {}
  },
  onShow = undefined,
  onHide = undefined
}) {
  const $trigger = $(trigger);
  const modalCloseHtml = '<button class="modal-close"></button>';
  const modalHandleHtml = '<div class="mobile-handle"></div>';

  const $modal = $(`
    <div class="b-modal v4${cssClass ? ' ' + cssClass : ''}${isSlideModal ? ' b-slide_modal' : ''}">
      <div class="modal-shade"></div>
      <div class="modal-inner">
        ${isSlideModal ? modalHandleHtml : modalCloseHtml} 
        <div class="v4">
          <section class='b-fbl-loader'>
            <div class='item'>
              <header class='modal-centered'>
                <div class='modal-title'></div>
                <div class='modal-note'></div>
              </header>
              <section>
                <div class='b-input'><input type='text' /></div>
                <div class='b-input'><input type='text' /></div>
                <div class='b-input textarea'><textarea></textarea></div>
                <input type='submit' disabled value='' />
                <div class='modal-submit-note'>
                  <div></div><div></div><div></div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>
  `);
  let vueApp = null;
  let swipeListener = null;

  $modal.appendTo(document.body);

  const modal = showModal({
    $modal,
    show() {
      addScrollableSelector('[data-scroll-lock-scrollable]');
      disablePageScroll();

      if (isSlideModal) {
        import('swipe-listener').then(({ default: SwipeListener }) => {
          const mobileHandleNode = $modal[0].querySelector('.mobile-handle');

          swipeListener = SwipeListener(mobileHandleNode);

          mobileHandleNode.addEventListener('swipe', e => {
            if (e.detail.directions.bottom &&
              e.detail.y[1] - e.detail.y[0] > MOBILE_HANDLE_SWIPE_THRESHOLD
            ) {
              modal.hide();
            }
          });
        });
      }

      $modal.addClass('is-active');

      if (onShow) {
        onShow();
      }
    },
    hide() {
      removeScrollableSelector('[data-scroll-lock-scrollable]');
      enablePageScroll();

      if (swipeListener) {
        swipeListener.off();
        swipeListener = null;
      }

      $modal.removeClass('is-active');

      if (onHide) {
        onHide();
      }
    },
    isHiddenCheck: () => !$modal.hasClass('is-active'),
    $trigger
  });
  const extendedModal = {
    ...modal,
    destroy() {
      modal.hide();

      modal.destroy();
      $modal.remove();

      if (vueApp) {
        vueApp.unmount();
      }
    }
  };

  if (isDestroyOnHide) { $modal.one('modal:hide', extendedModal.destroy); }

  $modal.find('.modal-shade').on('click', modal.hide);
  $modal.find('.modal-close').on('click', modal.hide);

  renderVueApp({
    $modal,
    modal,
    vue: {
      Component,
      props: props || $trigger.data(),
      eventHandlers
    },
    isCloseOnSubmit,
    isModalCloseHandler,
    isSlideModal
  }).then(app => vueApp = app);

  return extendedModal;
}

import delay from 'delay';
async function renderVueApp({
  $modal,
  modal,
  vue: {
    Component,
    props,
    eventHandlers
  },
  isCloseOnSubmit,
  isModalCloseHandler,
  isSlideModal
}) {
  const { createApp, h } = await import(/* webpackChunkName: "vue" */ 'vue');
  const combinedEventHandlers = {
    ...eventHandlers
  };

  if (isModalCloseHandler) {
    combinedEventHandlers['onModal:close'] = modal.hide;
  }
  if (isCloseOnSubmit) {
    combinedEventHandlers['onModal:submit'] = modal.hide;
  }

  const vueApp = createApp({
    setup: () => () => h(Component, { ...props, ...combinedEventHandlers })
  });
  vueApp.mount($modal.find('.modal-inner > .v4').empty()[0]);

  return vueApp;
}
