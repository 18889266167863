import imagesloaded from 'imagesloaded';
import Turbolinks from 'turbolinks';

import CustomSelect from '@/views/custom_select';
import PhoneWithCountryCode from '@/views/phone_with_country_code';
import Switcher from '@/views/switcher';
import { buildMap } from '@/views/map';
import { makeModal } from '@/views/modal/make_modal';

import { toLandingLeadForm } from '@/articles/landing_lead_form';

import SweetScroll from '@/helpers/sweet_scroll';
import { isMobile } from '@/helpers/mobile_detect';
import track from '@/helpers/track';

const IMAGE_ACTIVE = '/assets/static/b-map/marker-opened.svg';

let map = null;
let storageApp = null;

pageUnload('storages_show', () => {
  if (map) {
    map.destroy();
    map = null;
  }
  if (storageApp) {
    storageApp.unmount();
    storageApp = null;
  }
});

pageLoad('storages_show', async () => {
  if (!document.querySelector('.storages-v4-show')) { return; }

  const $storagePhoto = $('.slide_1 .photos .photo');
  if ($storagePhoto.length) {
    imagesloaded($storagePhoto.toArray(), () => initPhotos($storagePhoto));
  }

  const $node = $('#storage_app');
  renderStorageApp($node[0], {
    isSoon: $node.data('is_soon'),
    boxes: $node.data('boxes'),
    fabricBoxes: $node.data('fabric_boxes'),
    levels: $node.data('levels'),
    guideSizes: $node.data('guide_sizes'),
    leadType: $node.data('lead_type'),
    leadSubmitUrl: $node.data('lead_submit_url'),
    countryCodes: $node.data('country_codes')
  }).then(app => storageApp = app);

  buildStorageMap();
});

export function initPhotos($photos) {
  $photos.magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });
}

export function buildStorageMap() {
  const mapNode = document.querySelector('.b-map');
  if (mapNode) {
    const observer = new IntersectionObserver(singleStorageMapObserverCallback, {
      rootMargin: '40px',
      threshold: [0, 1]
    });
    observer.observe(mapNode);
    $(document).one('turbolinks:before-cache', () => observer.unobserve(mapNode));
  }
}

async function singleStorageMapObserverCallback([entry], observer) {
  if (!entry.isIntersecting) { return; }

  const mapNode = entry.target;
  observer.unobserve(mapNode);

  map = (await buildMap({
    node: mapNode,
    leadProps: $(mapNode).data('lead-props'),
    isAutoCenter: false,
    isAutoOpen: true
  })).map;
}

async function renderStorageApp(node, props) {
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: App } = await import('@/vue/storage_schema_boxes/index');

  const app = createApp(App, props);
  app.mount(node);

  return app;
}
