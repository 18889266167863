import { throttle } from 'throttle-debounce';

import rem from '@/helpers/rem';
import SweetScroll from '@/helpers/sweet_scroll';

let swiper = null;
let lax = null;

pageUnload('pages_qr', () => {
  if (lax) {
    lax.removeDriver();
  }

  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

pageLoad('pages_qr', async () => {
  await initSwiper();

  const { default: laxModule } = await import('lax.js');
  lax ||= laxModule;

  lax.init();
  lax.addDriver('scrollY', () => window.scrollY);

  let isFixed = false;
  let isRepositioned = false;
  const brkY = rem(870); // Y coordinate at which phone switches to fixed state
  const slideYDelta = rem(500); // how much Y must be scrolled before phone screen is changed
  const fixatorBreakpoints = [ // Y coordiates of phone screen changes
    brkY + slideYDelta * 0,
    brkY + slideYDelta * 1,
    brkY + slideYDelta * 2,
    brkY + slideYDelta * 3
  ];
  let activeSlideIndex = null;
  const slidesYOffset = slideYDelta * fixatorBreakpoints.length;
  const repositionY = brkY + slidesYOffset + Math.round(slideYDelta * 2.0 / 3);

  // set css var in order to change .slide-1 height
  const slideNode = document.querySelector('article.slide-1');
  slideNode.style.setProperty('--slidesYOffset', `${slidesYOffset}px`);
  slideNode.style.setProperty('--repositionY', `${repositionY}px`);
  const fixatorNode = document.querySelector('.phone-fixator');
  const rtlMultiplier = window.IS_RTL ? -1 : 1;

  lax.addElements('.phone-container', {
    scrollY: {
      rotate: [[0, brkY], [25.34 * rtlMultiplier, 0]],
      translateY: [[0, brkY], [0, rem(714)]]
    }
  }, {
    onUpdate: function(driverValues, domElement) {
      const scrollY = driverValues.scrollY[0];

      // switch to fixed state
      if (scrollY >= brkY && !isFixed) {
        isFixed = true;
        fixatorNode.classList.add('is-fixed');
      } else if (scrollY < brkY && isFixed) {
        isFixed = false;
        fixatorNode.classList.remove('is-fixed');
      }

      // switch to repositioned state
      if (scrollY >= repositionY && !isRepositioned) {
        isRepositioned = true;
        fixatorNode.classList.add('is-repositioned');
      } else if (scrollY < repositionY && isRepositioned) {
        isRepositioned = false;
        fixatorNode.classList.remove('is-repositioned');
      }

      // activate slide
      for (let i = fixatorBreakpoints.length - 1; i >= 0; i--) {
        if (scrollY > fixatorBreakpoints[i]) {
          if (activeSlideIndex !== i) {
            activeSlideIndex = i;
            swiper.slideTo(i);
          }
          break;
        }
      }
    }
  });

  lax.addElements('.phone-container .screen-0', {
    scrollY: { opacity: [[0, brkY], [1, 0], { easing: 'easeInCubic' }] }
  });
  lax.addElements('.phone-container .other-screens', {
    scrollY: { opacity: [[0, brkY], [0, 1]] }
  });
  lax.addElements('.how-it-works', {
    scrollY: { translateY: [[brkY - rem(110), brkY], [rem(-110), 0]] }
  });

  const brkY2 = brkY + slideYDelta * fixatorBreakpoints.length + rem(1000);
  lax.addElements('.phone-isometric', {
    scrollY: {
      translateX: [[brkY2, brkY2 + rem(320)], [rem(44) * rtlMultiplier, 0]],
      translateY: [[brkY2, brkY2 + rem(320)], [rem(34), 0]]
    }
  });

  $('.sticky-button.stores').on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.slide-3'), { duration: 750 }
    );
  });
});

async function initSwiper() {
  const { default: Swiper } = await import('swiper');

  swiper = new Swiper(document.querySelector('.how-it-works .slides'), {
    wrapperClass: 'slides-wrapper',
    slideClass: 'slide',
    speed: 500,
    slidesPerView: 'auto',
    allowTouchMove: false,
    spaceBetween: 0,
    on: {
      slideChange() {
        const $activeScreen = $('.other-screens .screen-active');
        const $nextScreen = $('.other-screens').find('img').eq(this.realIndex);

        $activeScreen.removeClass('screen-active');
        $nextScreen.addClass('screen-active');
      }
    }
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
}
