let storagesApp = null;
import trackVk from '@/helpers/track_vk';

$(document).on('turbolinks:before-cache', () => {
  if (storagesApp) {
    storagesApp.unmount();
    storagesApp = null;
  }
});

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.v4.storages_map #storages_app');
  if (!node) { return; }

  const observer = new IntersectionObserver(storagesMapObserverCallback, {
    rootMargin: '40px',
    threshold: [0, 1]
  });
  observer.observe(node);
  $(document).one('turbolinks:before-cache', () => observer.unobserve(node));
});

async function storagesMapObserverCallback([entry], observer) {
  if (!entry.isIntersecting) { return; }

  const mapNode = entry.target;
  observer.unobserve(mapNode);

  const $node = $(mapNode);
  renderStoragesApp(mapNode, {
    mapType: $node.data('map_type'),
    storages: $node.data('storages'),
    mapDataNode: $node[0],
    presetFilterOptions: $node.data('preset_filter_options'),
    isDistricts: $node.data('is_districts'),
    allBoxSizes: $node.data('all_box_sizes'),
    leadProps: $node.data('lead-props'),
    graphqlUrl: $node.data('graphql_url')
  }).then(app => storagesApp = app);
  trackVk('view_storages');
}

async function renderStoragesApp(node, props) {
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: App } = await import('@/vue/storages_map-v4/index');

  const app = createApp(App, props);
  app.mount(node);

  return app;
}
